.sp-icon {

    &--fill,
    &--filled {
        fill: var(--mat-icon-color);

        svg {
            fill: var(--mat-icon-color);
        }
    }

    &--outline, &--outlined {
        path {
            stroke: var(--mat-icon-color);
        }
    }

    &--button {
        width: 10px !important;
    }
}