/* #### Generated By: http://www.cufonfonts.com #### */

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Caros";
  font-style: normal;
  font-weight: normal;
  src: local("Caros"), url("caros.woff2") format("woff2");
}

@font-face {
  font-family: "Caros Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Caros Bold"), url("caros-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Caros Light";
  font-style: normal;
  font-weight: normal;
  src: local("Caros Light"), url("caros-light.woff2") format("woff2");
}

@font-face {
  font-family: "Caros Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Caros Medium"), url("caros-medium.woff2") format("woff2");
}
