@mixin icon-color($theme) {

    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $dark-theme-background, $light-theme-background);
    $foreground: if($is-dark-theme, $dark-theme-foreground, $light-theme-foreground);

    .sp-icon {
        color: map-get($foreground, text);

        &--fill {
            fill: map-get($foreground, primary-icon);

            svg {
                fill: map-get($foreground, primary-icon);
            }
        }

        &--outline {
            path {
                stroke: map-get($foreground, primary-icon);
            }
        }
    }

    .mat-primary,
    .mat-accent,
    .mat-warn {
        .sp-icon {
            color: white;

            &--fill {
                fill: white;

                svg {
                    fill: white;
                }
            }

            &--outline {
                path {
                    stroke: white;
                }
            }
        }
    }
}

@mixin icon-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include icon-color($theme);
    }
}