@use '@angular/material/theming';

$white: #FFFFFF;

$sp-light-theme-background: (
    background:white,
    background-accent: white,
    panel:$white,
    card:white,
    table-header:#F6F7FB,
    divider:var(--border-color),
    icon:#6e788f
);

$sp-dark-theme-background: (
    background:var(--dark-theme-background),
    background-accent: var(--dark-theme-background-accent),
    panel:#14222F,
    card:#14222F,
    table-header:#192d3f,
    divider:var(--border-color-dark),
    icon:#6e788f
);

$sp-light-theme-foreground: (
    border:#ecedf4,
    text:#192d3f,
    text-medium-emphasis:#192d3f,
    text-disabled-emphasis:#192d3f
);

$sp-dark-theme-foreground: (
    border:transparent,
    text:rgba($white, 87%),
    text-medium-emphasis:rgba($white, 60%),
    text-disabled-emphasis:rgba($white, 38%)
);

//=====================================================================
// Functions
//=====================================================================

//=====================================================================
// Themes
//=====================================================================

//---------------------------------------------------------------------
// Panel
//---------------------------------------------------------------------

@mixin sp-panel-color($theme) {
    $color-config: mat-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);
    $warn: map-get($color-config, warn);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    .sp-panel {
        color: map-get($foreground, text);

        &__container {
            background-color: map-get($background, panel);
        }

        &__header {
            &--bordered {
                border: 1px solid map-get($foreground, border);
            }
        }
    }

}

@mixin sp-panel-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-panel-color($theme);
    }
}

//---------------------------------------------------------------------
// sp-buttons
//---------------------------------------------------------------------

@mixin sp-buttons-color($theme) {
    $color-config: mat-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);
    $warn: map-get($color-config, warn);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    .sp-clear-filters-button {
        fill: map-get($background, icon);
    }

}

@mixin sp-buttons-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-buttons-color($theme);
    }
}

//---------------------------------------------------------------------
// Table
//---------------------------------------------------------------------

@mixin sp-table-color($theme) {
    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);

    .sp-table {
        &__th {
            background-color: map-get($background, table-header);
        }
    }

    .mat-table {
        background-color: transparent;
    }

    .mat-paginator {
        background-color: transparent;
    }
}

@mixin sp-table-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-table-color($theme);
    }
}

//---------------------------------------------------------------------
// Input search
//---------------------------------------------------------------------

@mixin sp-input-search-color($theme) {
    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    .sp-input-search {
        &__container {
            background-color: map-get($background, background);
            //border-color: map-get($background, divider);
        }

        &__input {
            color: map-get($foreground, text);
        }
    }
}

@mixin sp-input-search-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-input-search-color($theme);
    }
}

//---------------------------------------------------------------------
// Editable
//---------------------------------------------------------------------

@mixin sp-editable-color($theme) {
    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    .sp-editable {}
}

@mixin sp-editable-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-editable-color($theme);
    }
}

//---------------------------------------------------------------------
// Notification
//---------------------------------------------------------------------

@mixin sp-notification-color($theme) {
    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    // .sp-notification {
    //     &-success {
    //         &__message {
    //             color: map-get($foreground, text);
    //         }
    //     }
    // }
}

@mixin sp-notification-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-notification-color($theme);
    }
}

//---------------------------------------------------------------------
// Card
//---------------------------------------------------------------------

@mixin sp-card-color($theme) {
    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    .sp-card {
        background-color: map-get($background, card);
        color: map-get($foreground, text);
    }
}

@mixin sp-card-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-card-color($theme);
    }
}

//---------------------------------------------------------------------
// Upload image
//---------------------------------------------------------------------

@mixin sp-upload-image-color($theme) {
    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $sp-dark-theme-background, $sp-light-theme-background);
    $foreground: if($is-dark-theme, $sp-dark-theme-foreground, $sp-light-theme-foreground);

    .sp-upload-image {
        &__preview {
            border: 2px dashed map-get($background, divider);
            background-color: map-get($background, background-accent);
        }
    }
}

@mixin sp-upload-image-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include sp-upload-image-color($theme);
    }
}

//---------------------------------------------------------------------
// Theme
//---------------------------------------------------------------------

@mixin sp-library-theme($theme) {
    @include sp-editable-theme($theme);
    @include sp-table-theme($theme);
    @include sp-notification-theme($theme);
    @include sp-card-theme($theme);
    @include sp-panel-theme($theme);
    @include sp-input-search-theme($theme);
    @include sp-buttons-theme($theme);
    @include sp-upload-image-theme($theme);
}

@mixin sp-library-color($theme) {
    @include sp-editable-color($theme);
    @include sp-table-color($theme);
    @include sp-notification-color($theme);
    @include sp-card-color($theme);
    @include sp-panel-color($theme);
    @include sp-input-search-color($theme);
    @include sp-buttons-color($theme);
    @include sp-upload-image-color($theme)
}