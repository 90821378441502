html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

body {
    font-family: 'Poppins', Montserrat, Caros, Roboto, "Helvetica Neue", sans-serif;
    background-color: $background-color;
}

button:focus {
    outline: none;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

/*
 * ====================================================================
 * = Scrollbar
 * ====================================================================
 */

body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 5px $background-color;
}

.none-scroll::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

// ::-webkit-scrollbar-thumb {
//     background-color: var(--dark-theme-background-accent);
//     border-radius: 8px;
//     box-shadow: inset 0 0 0 5px var(--dark-theme-background);
// }