.sp-entity {
    &__photo {
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-radius: var(--border-radius);
        border: 1px solid var(--border-color-dark);

        &--sm {
            width: 30px;
            height: 30px;
        }

        &--md {
            width: 45px;
            height: 45px;
        }

        &--lg {
            width: 131px;
            height: 131px;
        }

        &--circle {
            border-radius: 50%;
        }
    }

    &__icon {
        width: 40px;
        height: 40px;
        object-fit: cover;
        overflow: hidden;

        &--athlete {
            border-radius: 50%;
        }
    }
}