.sp-menu {
    /* Para sobreescribir la limitante que tiene mat-menu*/
    max-width: initial !important;

    &__content {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.sp-menu-option {
    &__icon {
        width: 16px;
        margin-right: 16px;
    }
}