@mixin base-color($theme) {

    $color-config: mat-get-color-config($theme);
    $is-dark-theme: map-get($color-config, 'is-dark');

    $background: if($is-dark-theme, $web-dark-theme-background, $web-light-theme-background);
    $foreground: if($is-dark-theme, $web-dark-theme-foreground, $web-light-theme-foreground);

    ::-webkit-scrollbar-thumb {
        background-color: map-get($background, scrollbar);
        border-radius: 8px;
        box-shadow: inset 0 0 0 5px map-get($background, scrollbar-inset);
    }

    ::-webkit-scrollbar-corner {
        background-color: map-get($background, background);
    }

    select {
        background-color: map-get($background, background-accent);
        color: map-get($foreground, text);
    }
}

@mixin base-theme($theme) {
    $color-config: mat-get-color-config($theme);

    @if $color-config !=null {
        @include base-color($theme);
    }
}