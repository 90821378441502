.sp-button {
  position: relative;
  font-weight: 500;

  &--stroked {
    svg {
      fill: $button-flat-color;
    }
  }

  &--icon-sm {
    mat-icon {
      svg {
        height: 12px;
      }
    }
  }

  &--with-icon {
    mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
    }

    svg {
      stroke: $button-basic-color;

      path {
        stroke: $button-basic-color;
      }
    }
  }

  &--bold {
    .mat-button-wrapper {
      font-weight: $font-weight-bold;
    }
  }

  &--filter {
    border-radius: 12px;
    margin: 0 5px;
  }

  &--secondary {
    color: var(--font-color-secondary);
  }

  &--selected-accent {
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 3px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background-color: $accent;
    }
  }

  &--icon-filled {
    .mat-icon {
      svg {
        fill: currentColor;
      }
    }
  }

  &--icon-outlined {
    .mat-icon {
      svg {
        stroke: currentColor;
      }
    }
  }
}

.sp-icon-button {
  color: var(--mat-icon-color) !important;

  mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--filled {
    .mat-icon {
      svg {
        fill: currentColor;
      }
    }
  }

  &--outlined {
    .mat-icon {
      svg {
        stroke: currentColor;

        path {
          stroke: currentColor;
        }
      }
    }
  }

  &--outline {

    svg,
    path {
      stroke: $button-basic-color;
      stroke-opacity: 1;
    }
  }

  &--fill {
    svg {
      fill: $button-basic-color;
    }
  }

  &--publish-not-verified {
    svg {
      fill: #ce7e45;
    }
  }

  &--publish-verified {
    svg path {
      fill: #658e30;
    }
  }

  &--publish-rejected {
    svg path {
      fill: #ea1c2c;
    }
  }

  &--fill-path {
    path {
      fill: $button-basic-color;
    }
  }

  &--list {
    svg {
      height: 22px;
    }
  }

  &--md {
    svg {
      height: 18px;
    }
  }

  &--sm {
    svg {
      height: 16px;
    }
  }

  &--option {
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.sp-menu-button {
  &__image {
    width: 24px;
    height: 24px;
  }

  mat-icon {
    position: relative;
    width: 18px;
    color: inherit !important;
  }

  svg {
    fill: $menu-option-color;
    stroke: none;
  }

  &--outlined {
    svg {
      fill: none;
      stroke: $menu-option-color;
      stroke-opacity: 1;
    }

    path {
      fill: none;
      stroke: $menu-option-color;
      stroke-opacity: 1;
    }
  }

  &--md {
    svg {
      padding: 1.5px;
    }
  }

  &--sm {
    svg {
      padding: 3px;
    }
  }

  &--mat-icon-width-i {
    .mat-icon {
      width: initial;
    }
  }

  &--profile {
    mat-icon {
      width: initial;
    }
  }

  &--stripe {
    padding: 0 10px !important;

    mat-icon {
      width: initial;
      margin-right: 6px !important;
      margin-left: -3px;
    }
  }
}

.mat-icon-button.sp-edit-photo-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}