$border: 1px solid var(--border-color);

.sp-container {
    &--bordered {
        border: $border;
    }

    &--rounded {
        border-radius: var(--border-radius);
    }

    &--btop {
        border-top: $border;
    }

    &--bright {
        border-top: $border;
    }

    &--bbottom {
        border-bottom: $border;
    }

    &--bleft {
        border-top: $border;
    }

    &__dialog {
        &-panel-class {
            &--full-height {
                max-height: 100% !important;
            }
        }

        &-content {
            &--85-height {
                .mat-dialog-content {
                    max-height: 85vh !important;
                }
            }

            &--full-height {
                max-height: 100% !important;
            }
        }
    }
}