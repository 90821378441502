.sp-tag {
    padding: 5px 10px;
    border-radius: var(--border-radius);
    font-size: .8rem;
    background-color: rgba(60, 108, 247, 0.1);
    color: var(--primary-web);

    &--secondary {
        background-color: var(--dark-theme-background-accent);
    }

    &--active {
        background-color: rgb(5, 105, 13);
        color: rgb(215, 247, 194);
    }

    &--cancelled {
        background-color: rgba(0, 0, 0, 0.2);
        color: rgb(255, 255, 255, 0.4);
    }

    &--institution {
        font-size: 1rem;
    }
}