$border: 1px solid $border-color;

.horizontal-spacer {
  flex: 1 1 auto;
}

.sp-border {
  border: $border;

  &-top {
    border-top: $border;
  }

  &-right {
    border-right: $border;
  }

  &-bottom {
    border-bottom: $border;
  }

  &-left {
    border-left: $border;
  }
}

// Borde redondeado por defecto de la aplicación
.sp-rounded {
  border-radius: 6px;
}

.sp-mat-icon-color {
  color: var(--mat-icon-color);

  &--fill {
    svg {
      fill: var(--mat-icon-color);
    }
  }
}

.sp-font {
  font-weight: $font-weight-regular;

  &--primary {
    color: $font-color-primary;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }
}

.sp-no-data {
  color: $font-color-no-data;
}

.sp-mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
  pointer-events: none;
}

.sp-disabled,
.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

.sp-text-overflow {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sp-cursor-pointer {
  cursor: pointer;
}

.sp-text {
  display: inline-block;

  &--instruction {
    color: $color4;
  }

  &--note {
    color: #a8abb9;
  }
}

.sp-selectable {
  cursor: pointer;
}

.sp-gap {
  gap: 10px;
}