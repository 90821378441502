.sp-dialog {
    &__content {
        &--full-height {
            mat-dialog-content {
                max-height: 100vh;
            }
        }
    }

    &__panel-class {
        &--scrollable {
            .mat-dialog-content {
                max-height: 65vh;
            }
        }

        &--column {
            .mat-dialog-content {
                display: flex;
                flex-direction: column;
            }
        }
    }
}