.sp-link-button {
    color: $primary;
    text-decoration: none;

    &:hover {
        color: $primary;
        text-decoration: none;
    }

    &--bold {
        font-weight: 700 !important;
    }

    &--dark {
        color: black !important;
    }
    &--white {
        color: white !important;
    }

    &--selected {
        color: $accent !important;
    }
}
