.sp-card {
    &__title {
        font-weight: 700;
        line-height: 24px;
        font-size: 20px;
        &--secondary {
            font-size: 16px;
        }
        &--tertiary {
            font-size: 12px;
        }
    }
    &__subtitle {
        font-size: 14px;
        font-weight: 500;
        color: var(--font-color-secondary);
    }
}
