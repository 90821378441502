/*
 * =====================================================
 * = Bootstrap
 * =====================================================
*/

svg {
  vertical-align: initial;
}

/*
 * =====================================================
 * = Angular Material
 * =====================================================
*/

$backdrop-color: rgba(60, 72, 88, 0.75);

.mat-tab-label.mat-tab-label-active {
  color: $accent;
}

.mat-ink-bar {
  background-color: $accent;
}

.cdk-overlay-dark-backdrop {
  background-color: $backdrop-color;
}

.cdk-overlay-pane {
  max-height: 95vh;
}

.mat-list-base .mat-list-option {
  font-size: $font-size;
}

.mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

button[mat-flat-button]:not(.mat-primary) {
  background-color: #162737 !important;
}

/*
 * ====================================================================
 * = radio-button
 * ====================================================================
 */
.mat-radio-container {
  display: none !important;
}

.mat-list-item-disabled {
  opacity: 0.5;
}

.mat-button-toggle-group {
  height: 36px;
}

/**
------------------------------------------------------------------
- Estilos para control de teléfono
------------------------------------------------------------------
 */

.ngx-mat-intl-tel-input {
  .country-search {
    outline: none;
    color: inherit !important;
    background-color: transparent !important;
  }

  .country-selector {
    opacity: 1 !important;
    bottom: 8px !important;
    width: 80px;
    color: inherit !important;
  }

  .mat-menu-content {
    max-height: 300px;
    max-width: 100%;
  }

  .mat-menu-panel {
    max-width: 350px;
  }

  .country-list-button {
    font-size: 1rem !important;
    color: inherit !important;
  }

  .ngx-mat-tel-input-container {
    .mat-button-wrapper {
      justify-content: initial;
    }
  }

  input:not(.country-search) {
    bottom: 3px;
  }
}

/**
------------------------------------------------------------------
- Ag Grid
------------------------------------------------------------------
 */

.ag-center-aligned-header {

  .ag-header-group-text {
    width: 100%;
    text-align: center;
  }

  .ag-header-cell-text {
    width: 100%;
    text-align: center;
  }

  &--with-filter {
    .ag-header-cell-text {
      margin-left: 16px;
    }
  }
}