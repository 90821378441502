.sp-text {
    font-weight: var(--font-weight-regular);

    &--no-data {
        color: var(--font-color-no-data);
    }

    &--medium {
        font-weight: var(--font-weight-medium);
    }

    &--semibold {
        font-weight: var(--font-weight-semibold);
    }

    &--bold {
        font-weight: var(--font-weight-bold);
    }

    &--xs {
        font-size: 0.8rem;
    }

    &--sm {
        font-size: 0.9rem;
    }

    &--md {
        font-size: 1.2rem;
    }

    &--lg {
        font-size: 1.5rem;
    }

    &--xg {
        font-size: 2rem;
    }
}

.sp-text-email {
    text-transform: lowercase;
}